import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_URL, ApiPath } from 'src/enums/apiPath';
import { RoutePath } from 'src/enums/routePath';
import {
  ADMIN_ACCESS_TOKEN_KEY,
  ADMIN_REFRESH_TOKEN_KEY,
  ADMIN_SAVE_SESSION,
  USER_ACCESS_TOKEN_KEY,
  USER_REFRESH_TOKEN_KEY,
  USER_SAVE_SESSION
} from 'src/constants/auth';
import {
  getStorageItem,
  getToken,
  removeToken,
  setSessionItem,
  setStorageItem
} from './storage';

const axiosUser = axios.create({
  baseURL: API_URL,
  timeout: 300000,
  headers: {
    'Accept-Language': 'ja'
  }
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Add a request interceptor
axiosUser.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const accessToken = getToken(USER_ACCESS_TOKEN_KEY);
    if (config.headers && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['ngrok-skip-browser-warning'] = 'any';
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosUser.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axiosUser(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      const refreshToken = getToken(USER_REFRESH_TOKEN_KEY);

      return new Promise(function (resolve, reject) {
        axios
          .post(
            `${API_URL}${ApiPath.REFRESH_TOKEN}`,
            { refresh_token: refreshToken },
            {
              params: {
                refresh_token: refreshToken
              }
            }
          )
          .then(({ data }) => {
            removeToken(USER_ACCESS_TOKEN_KEY);
            const isSaveSession = getStorageItem(USER_SAVE_SESSION);
            if (Boolean(isSaveSession)) {
              setStorageItem(USER_ACCESS_TOKEN_KEY, data.access_token);
            } else {
              setSessionItem(USER_ACCESS_TOKEN_KEY, data.access_token);
            }

            originalRequest.headers['Authorization'] =
              `Bearer ${data.access_token}`;
            processQueue(null, data.access_token);
            resolve(axiosUser(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            removeToken(USER_ACCESS_TOKEN_KEY);
            removeToken(USER_REFRESH_TOKEN_KEY);
            window.location.href = RoutePath.LOGIN;
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

const axiosAdmin = axios.create({
  baseURL: API_URL,
  timeout: 300000,
  headers: {
    'Accept-Language': 'ja'
  }
});
// Add a request interceptor
axiosAdmin.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const accessToken = getToken(ADMIN_ACCESS_TOKEN_KEY);
    if (config.headers && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['ngrok-skip-browser-warning'] = 'any';
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosAdmin.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axiosAdmin(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      const refreshToken = getToken(ADMIN_REFRESH_TOKEN_KEY);

      return new Promise(function (resolve, reject) {
        axios
          .post(
            `${API_URL}${ApiPath.REFRESH_TOKEN}`,
            { refresh_token: refreshToken },
            {
              params: {
                refresh_token: refreshToken
              }
            }
          )
          .then(({ data }) => {
            removeToken(ADMIN_ACCESS_TOKEN_KEY);
            const isSaveSession = getStorageItem(ADMIN_SAVE_SESSION);
            if (Boolean(isSaveSession)) {
              setStorageItem(ADMIN_ACCESS_TOKEN_KEY, data.access_token);
            } else {
              setSessionItem(ADMIN_ACCESS_TOKEN_KEY, data.access_token);
            }

            originalRequest.headers['Authorization'] =
              `Bearer ${data.access_token}`;
            processQueue(null, data.access_token);
            resolve(axiosAdmin(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            removeToken(ADMIN_ACCESS_TOKEN_KEY);
            removeToken(ADMIN_REFRESH_TOKEN_KEY);
            window.location.href = RoutePath.ADMIN_LOGIN;
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export { axiosUser, axiosAdmin };
