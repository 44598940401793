import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Layout, Menu, Spin } from 'antd';
import logo from 'src/assets/images/logo-login-admin.png';
import logoutIcon from 'src/assets/icons/logout.png';
import { logoutAdmin } from 'src/services/auth';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import Building from 'src/assets/icons/Building';
import Customer from 'src/assets/icons/Customer';
import User from 'src/assets/icons/User';
import { useAuthAdmin } from 'src/hooks/useAuth';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const { Header, Content, Sider } = Layout;

const menus = [
  {
    key: RoutePath.ADMIN_BUILDING,
    label: <a>物件一覧</a>,
    icon: <Building />
  },
  {
    key: RoutePath.ADMIN_CUSTOMER,
    label: <a>顧客アカウント</a>,
    icon: <Customer />
  },
  {
    key: RoutePath.ADMIN_USER,
    label: <a>管理アカウント</a>,
    icon: <User />
  }
];

const App: React.FC = () => {
  const { loading } = useAuthAdmin();
  const user: any = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [menuActive, setMenuActive] = useState<string[] | undefined>();

  const handleClickMenu = (path: string) => {
    navigate(path);
    setMenuActive([path]);
  };

  const handleOpenMenu = (openKeys: string[]) => {
    setMenuActive(openKeys);
  };

  useEffect(() => {
    const menusMatch = menus
      .filter((menu) => matchPath(`${menu.key}/*`, location.pathname))
      .map((menu) => menu.key);
    if (menusMatch?.length !== 0) setMenuActive(menusMatch);
  }, [location.pathname]);

  useEffect(() => {
    document.title = '眺望管理画面';
  }, []);

  if (loading)
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            siderBg: 'linear-gradient(179deg, #5B59E5 -8.36%, #1A2B5F 97.14%)'
          },
          Menu: {
            itemBg: 'transparent',
            itemColor: 'white',
            itemSelectedColor: '#1A2B5F',
            itemSelectedBg: 'white',
            itemHoverColor: 'white',
            itemHoverBg: '#5B56E5',
            itemBorderRadius: 0,
            itemMarginInline: 0
          }
        }
      }}
    >
      <Layout className="h-screen">
        <Header
          className="bg-white flex flex-row justify-between items-center h-14 px-4 py-3 z-10"
          style={{
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)'
          }}
        >
          <img src={logo} alt="" className="h-full w-auto" />
          <p>{user?.email || user?.name}</p>
        </Header>
        <Layout>
          <Sider width={200}>
            <div className="flex flex-col justify-between py-4 h-full">
              <Menu
                mode="inline"
                selectedKeys={menuActive}
                openKeys={menuActive}
                defaultOpenKeys={['sub1']}
                style={{ height: '100%', borderRight: 0 }}
                items={menus}
                onClick={(menu) => handleClickMenu(menu.key)}
                onOpenChange={(openKeys) => handleOpenMenu(openKeys)}
                className="custom-menu"
              />
              <Button
                className="rounded-lg border border-[#F5222D] mx-2 h-11 text-sm font-medium flex gap-2 justify-center"
                onClick={logoutAdmin}
              >
                <img src={logoutIcon} alt="" />
                <span className="text-[#F5222D]">ログアウト</span>
              </Button>
            </div>
          </Sider>
          <Layout className="h-[100vh-56px] overflow-y-auto">
            <Outlet />
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
