const Building = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
    >
      <path
        d="M13.5 1H0.5C0.223437 1 0 1.22344 0 1.5V11.5C0 11.7766 0.223437 12 0.5 12H13.5C13.7766 12 14 11.7766 14 11.5V1.5C14 1.22344 13.7766 1 13.5 1ZM12.875 4.25H9.5625V2.125H12.875V4.25ZM12.875 7.75H9.5625V5.25H12.875V7.75ZM5.4375 5.25H8.5625V7.75H5.4375V5.25ZM8.5625 4.25H5.4375V2.125H8.5625V4.25ZM1.125 5.25H4.4375V7.75H1.125V5.25ZM1.125 2.125H4.4375V4.25H1.125V2.125ZM1.125 8.75H4.4375V10.875H1.125V8.75ZM5.4375 8.75H8.5625V10.875H5.4375V8.75ZM12.875 10.875H9.5625V8.75H12.875V10.875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Building;
