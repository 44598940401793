import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ADMIN_ACCESS_TOKEN_KEY,
  USER_ACCESS_TOKEN_KEY
} from 'src/constants/auth';
import {
  fetchMeAdmin,
  fetchMeUser,
  logoutAdmin,
  logoutUser
} from 'src/services/auth';
import { loginSuccess, setUser } from 'src/store/reducers/authSlice';
import { getToken } from 'src/utils/storage';

export function useAuthAdmin() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fetchInfo = useCallback(async () => {
    try {
      const res = await fetchMeAdmin();
      dispatch(loginSuccess());
      dispatch(setUser(res.data?.data || { name: 'admin' }));
    } catch (error) {
      logoutAdmin();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const accessToken = getToken(ADMIN_ACCESS_TOKEN_KEY);
    if (!accessToken) {
      setLoading(false);
    } else {
      fetchInfo();
    }
  }, []);

  return {
    loading
  };
}

export function useAuthUser() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fetchInfo = useCallback(async () => {
    try {
      const res = await fetchMeUser();
      dispatch(loginSuccess());
      dispatch(setUser(res.data?.data || { name: 'admin' }));
    } catch (error) {
      logoutUser();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const accessToken = getToken(USER_ACCESS_TOKEN_KEY);
    if (!accessToken) {
      setLoading(false);
    } else {
      fetchInfo();
    }
  }, []);

  return {
    loading
  };
}
