export const setStorageItem = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const getStorageItem = (key: string) => {
  const value = window.localStorage.getItem(key);
  return value === null ? '' : value;
};

export const setSessionItem = (key: string, value: string) => {
  window.sessionStorage.setItem(key, value);
};

export const getSessionItem = (key: string) => {
  const value = window.sessionStorage.getItem(key);
  return value === null ? '' : value;
};

export const clearStorageItem = (key: string) =>
  window.localStorage.removeItem(key);

export const clearSessionItem = (key: string) =>
  window.sessionStorage.removeItem(key);

export const getToken = (key: string) => {
  const storageValue = window.localStorage.getItem(key);
  const sesstionValue = window.sessionStorage.getItem(key);
  return storageValue || sesstionValue || '';
};

export const removeToken = (key: string) => {
  window.localStorage.removeItem(key);
  window.sessionStorage.removeItem(key);
};
