import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  loggedInUser: false
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    loginSuccess(state) {
      state.loggedInUser = true;
    },
    reset(state) {
      state.user = {};
    }
  }
});

export const { setUser, reset, loginSuccess } = authSlice.actions;

export default authSlice.reducer;
