import React, { useEffect } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import { useAuthUser } from 'src/hooks/useAuth';

const CustomerLayout: React.FC = () => {
  const { loading } = useAuthUser();

  useEffect(() => {
    document.title = '眺望アプリ';
  }, []);

  if (loading)
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );

  return (
    <ConfigProvider>
      <Outlet />
    </ConfigProvider>
  );
};

export default CustomerLayout;
