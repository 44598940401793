import {
  USER_ACCESS_TOKEN_KEY,
  ADMIN_ACCESS_TOKEN_KEY
} from 'src/constants/auth';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { PrivateRouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import { getToken } from 'src/utils/storage';

export const PrivateRoute = ({ children, isAdmin }: PrivateRouteI) => {
  const isLogined = useMemo(() => {
    if (isAdmin) return !!getToken(ADMIN_ACCESS_TOKEN_KEY);
    return !!getToken(USER_ACCESS_TOKEN_KEY);
  }, [isAdmin]);

  return isLogined ? (
    children
  ) : (
    <Navigate
      to={`${isAdmin ? RoutePath.ADMIN_LOGIN : RoutePath.LOGIN}`}
      replace
    />
  );
};
