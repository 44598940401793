import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import store from './store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Inter', sans-serif"
        },
        components: {
          Button: {
            primaryShadow: 'none',
            defaultShadow: 'none'
          }
        }
      }}
      button={{ autoInsertSpace: false }}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ModalProvider>
              <DrawerProvider>
                <AppRouter />
              </DrawerProvider>
            </ModalProvider>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
