const User = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
    >
      <path
        d="M12.4141 10.4322C12.1194 9.73406 11.6916 9.09989 11.1547 8.56504C10.6195 8.02865 9.9854 7.60098 9.28754 7.30566C9.28129 7.30254 9.27504 7.30098 9.26879 7.29785C10.2422 6.59473 10.875 5.44941 10.875 4.15723C10.875 2.0166 9.14066 0.282227 7.00004 0.282227C4.85941 0.282227 3.12504 2.0166 3.12504 4.15723C3.12504 5.44941 3.75785 6.59473 4.73129 7.29941C4.72504 7.30254 4.71879 7.3041 4.71254 7.30723C4.01254 7.60254 3.38441 8.02598 2.84535 8.5666C2.30896 9.10186 1.8813 9.73593 1.58598 10.4338C1.29585 11.117 1.13939 11.8495 1.12504 12.5916C1.12462 12.6083 1.12755 12.6249 1.13364 12.6404C1.13974 12.6559 1.14888 12.6701 1.16053 12.682C1.17218 12.694 1.1861 12.7034 1.20147 12.7099C1.21684 12.7164 1.23336 12.7197 1.25004 12.7197H2.18754C2.25629 12.7197 2.31098 12.665 2.31254 12.5979C2.34379 11.3916 2.82816 10.2619 3.68441 9.40567C4.57035 8.51973 5.74691 8.03223 7.00004 8.03223C8.25316 8.03223 9.42973 8.51973 10.3157 9.40567C11.1719 10.2619 11.6563 11.3916 11.6875 12.5979C11.6891 12.6666 11.7438 12.7197 11.8125 12.7197H12.75C12.7667 12.7197 12.7832 12.7164 12.7986 12.7099C12.814 12.7034 12.8279 12.694 12.8396 12.682C12.8512 12.6701 12.8603 12.6559 12.8664 12.6404C12.8725 12.6249 12.8755 12.6083 12.875 12.5916C12.8594 11.8447 12.7047 11.1182 12.4141 10.4322V10.4322ZM7.00004 6.84473C6.28285 6.84473 5.60785 6.56504 5.10004 6.05723C4.59223 5.54941 4.31254 4.87441 4.31254 4.15723C4.31254 3.44004 4.59223 2.76504 5.10004 2.25723C5.60785 1.74941 6.28285 1.46973 7.00004 1.46973C7.71723 1.46973 8.39223 1.74941 8.90004 2.25723C9.40785 2.76504 9.68754 3.44004 9.68754 4.15723C9.68754 4.87441 9.40785 5.54941 8.90004 6.05723C8.39223 6.56504 7.71723 6.84473 7.00004 6.84473Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default User;
