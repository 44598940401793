import { PrivateRoute } from 'src/components/PrivateRoute';
import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { Suspense } from 'react';
import NotFound from 'src/pages/NotFound';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />

      {routes.map(
        ({ Component, isPrivate, path, children, Layout, isUser }) => {
          return (
            <Route
              key={path}
              element={
                isPrivate ? (
                  <PrivateRoute isAdmin={!isUser}>
                    <>{Layout}</>
                  </PrivateRoute>
                ) : (
                  Layout
                )
              }
            >
              <Route
                path={path}
                element={
                  <Suspense fallback={null}>
                    <Component />
                  </Suspense>
                }
              >
                {children?.map(
                  (
                    {
                      path: childPath,
                      Component: ChildComponent,
                      props: childProps
                    },
                    childIndex
                  ) => (
                    <Route
                      key={childIndex}
                      path={childPath}
                      element={
                        <Suspense fallback={null}>
                          <ChildComponent {...childProps} />
                        </Suspense>
                      }
                    />
                  )
                )}
              </Route>
            </Route>
          );
        }
      )}
    </Routes>
  );
};

export default AppRouter;
